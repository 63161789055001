<template>
    <div class="menu-body"
        v-if="$oidc.userProfile.roles.includes(roles.PLANTPILLARLEADER) || $oidc.userProfile.roles.includes(roles.PLANTPOLEADER)">
        <Loader v-if="showLoader" />
        <Breadcrumb />
        <div class="main-con" style="padding: 0">
            <div class="even-space-vertical-align">
                <div class="mat-info-con main-tab-act default-font-color bold-font">
                    The machine classification done here would be applicable hereafter, unless changed.
                </div>
            </div>
        </div>
        <div class="main-con">
            <div class="mat-info-con even-space-vertical-align">
                <div class="plant-dd" style="width: 100%; display: flex">
                    <div class="plant-dd">
                        <label for="plant" class="form-label select-lable lable-text"> Plant </label>
                        <select class="form-control select-text select-text-pd cust-st-icon" id="plantId"
                            :value="plantSelectedInAsmtHome" @change="plantChanged($event)">
                            <option v-for="plant in plantList" v-bind:key="plant.plantId" :value="plant.plantId">
                                {{ `${plant.plantName}, ${plant.regionCode}` }}
                            </option>
                        </select>
                    </div>
                    <div class="plant-dd" style="margin-left: 10px">
                        <label for="workcenter" class="form-label select-lable lable-text"> Work Center </label>
                        <select class="form-control select-text select-text-pd cust-st-icon" id="workcenterId"
                            :value="prevSelectedWorkCenter" @change="workcenterChanged($event)">
                            <option v-for="wc in workCenterList" v-bind:key="wc.workcenterId" :value="wc.workcenterId">
                                {{ wc.workcenterName }}
                            </option>
                        </select>
                    </div>
                </div>
            </div>
            <div style="display: flex; flex-wrap: wrap; padding: 0 2rem">
                <div class="machine-classification-tracker">
                    <b-table-simple responsive outlined id="machine-classification-tracker" sticky-header
                        class="machine-action-table scroll gn-table-b">
                        <b-thead head-variant="light">
                            <b-tr>
                                <b-th> MACHINES ( {{ statusCount.total }} ) </b-th>
                                <b-th> ALIAS </b-th>
                                <b-th>
                                    <b-form-radio size="lg" name="header-checkbox" id="chkNotReplicating"
                                        class="radio-class" v-model="allMachineNotReplicated" :value="true"
                                        @change="onHeaderMachineCheck($event, 'allMachineNotReplicated')">
                                    </b-form-radio>
                                    NOT REPLICATING ( {{ statusCount.nonRepl }} )
                                </b-th>
                                <b-th>
                                    <b-form-radio size="lg" name="header-checkbox" id="chkReplicating" class="radio-class"
                                        v-model="allMachineReplicating" :value="true"
                                        @change="onHeaderMachineCheck($event, 'allMachineReplicating')">
                                    </b-form-radio>
                                    REPLICATING ( {{ statusCount.repl }} )
                                </b-th>
                                <b-th>
                                    <b-form-radio size="lg" name="header-checkbox" id="chkReplicatingCompleted"
                                        class="radio-class" v-model="allReplicationCompleted" :value="true"
                                        @change="onHeaderMachineCheck($event, 'allReplicationCompleted')">
                                    </b-form-radio>
                                    REPLICATION COMPLETED ( {{ statusCount.replComp }} )
                                </b-th>
                            </b-tr>
                        </b-thead>
                        <b-tbody v-if="machineStatusList && machineStatusList.length !== 0">
                            <b-tr v-for="(rowData, index) in machineStatusList" :key="index"
                                :class="{ 'add-top-border': rowData.addBorder }">
                                <b-td>
                                    {{ rowData.machineName }}
                                </b-td>
                                <b-td>
                                    <div class="input-div" style="padding: 0.75rem" v-if="editingMode">
                                        <input class="input-f" v-model="rowData.machineAlias" placeholder="Type here"
                                            @input="changingF(rowData.machineAlias)" />
                                    </div>
                                    <span v-else>
                                        {{ rowData.machineAlias }}
                                    </span>
                                </b-td>
                                <b-td>
                                    <b-form-radio size="lg" :id="`${rowData.machineId}-id-non`" class="radio-class"
                                        :name="rowData.machineId + '-status-radio'" :value="1"
                                        @click="onMachineCheck($event, index, rowData, 'machineNotReplicated')"
                                        @change="onMachineCheck($event, index, rowData, 'machineNotReplicated')">
                                    </b-form-radio>
                                </b-td>

                                <b-td>
                                    <b-form-radio size="lg" :id="`${rowData.machineId}-id-repl`" class="radio-class"
                                        :name="rowData.machineId + '-status-radio'" :value="2"
                                        @click="onMachineCheck($event, index, rowData, 'machineNotReplicated')"
                                        @change="onMachineCheck($event, index, rowData, 'machineReplicating')">
                                    </b-form-radio>
                                </b-td>
                                <b-td>
                                    <b-form-radio size="lg" :id="`${rowData.machineId}-id-comp`" class="radio-class"
                                        :name="rowData.machineId + '-status-radio'" :value="3"
                                        @click="onMachineCheck($event, index, rowData, 'machineNotReplicated')"
                                        @change="onMachineCheck($event, index, rowData, 'ReplicationCompleted')">
                                    </b-form-radio>
                                </b-td>
                            </b-tr>
                        </b-tbody>
                        <b-tbody v-if="machineStatusList && machineStatusList.length === 0">
                            <tr>
                                <td class="text-center" style="height: 300px; text-align: center !important" rowspan="6"
                                    :colspan="machineDetailHeader.length">
                                    <img alt="no data" src="@/assets/No_Data.svg" />
                                    <div class="ed-date">No Machine Found</div>
                                </td>
                            </tr>
                        </b-tbody>
                        <b-tfoot> </b-tfoot>
                    </b-table-simple>
                </div>
                <div class="machine-classification-tracker display-flex" style="justify-content: end">
                    <div class="edit-button-div">
                        <b-button class="asmt-bt button-sm" style="padding: 0.5rem 1.2rem" pill variant="outline-primary"
                            @click="$router.go(-1)"> Go back </b-button>
                        <b-button class="asmt-bt button-sm" style="padding: 0.5rem 1.2rem; " pill
                            v-if="$oidc.userProfile.roles.includes(roles.PLANTPILLARLEADER) || $oidc.userProfile.roles.includes(roles.PLANTPOLEADER)"
                            variant="outline-primary" @click="showConfirmBox()"
                            :disabled="plantId.plantId !== loggedInUserData.plantId || !machineStatusList.length">
                            Update
                        </b-button>
                    </div>
                </div>
            </div>

            <b-modal id="saveConfirmBox" size="sm" ref="saveConfirmBox" hide-footer :data-backdrop="true"
                :no-close-on-backdrop="true" :no-close-on-esc="true" :visible="showConfirmation">
                <div class="row col-12 page-content-regular">
                    <p>
                        <span class="page-heading-tab"> Are you sure you want to save the changes? </span>
                    </p>
                </div>
                <div class="button-div" style="justify-content: right; display: flex; width: 100%">
                    <b-button class="btn ed-st-btn" style="border: transparent; background: transparent; color: #274ba7"
                        pill variant="outline-primary" @click="closeAlertPopup()">
                        Cancel
                    </b-button>
                    <b-button pill variant="outline-primary" style="padding: 0.25rem 1rem; margin-left: 1rem"
                        class="btn cancel-save-btn button-sm" @click="saveHandle()"> Proceed </b-button>
                </div>
            </b-modal>

            <b-modal id="successConfirmBox" size="sm" ref="successConfirmBox" hide-footer :data-backdrop="true"
                :no-close-on-backdrop="true" :no-close-on-esc="true" :visible="successConfirm">
                <div class="row col-12 page-content-regular">
                    <p>
                        <span class="page-heading-tab"> Machine Classification is Successfully Completed. </span>
                    </p>
                </div>
                <div class="button-div" style="justify-content: right; display: flex; width: 100%">
                    <b-button pill variant="outline-primary" style="padding: 0.25rem 1rem; margin-left: 1rem"
                        class="btn cancel-save-btn button-sm" @click="okClick()"> Ok </b-button>
                </div>
            </b-modal>

            <b-modal id="messagePopup" :visible="showError" ref="messagePopup" :no-close-on-backdrop="true"
                :no-close-on-esc="true" hide-footer hide-header-close>
                <!-- <template #modal-header="{ close }">
                     <p class="pop-up-title">Error</p>
                    <b-button size="sm" class="close-modal" @click="close()"> x </b-button>
                </template> -->
                <!-- <template #default="{}"> -->
                <div class="row col-12 page-content-regular" style="margin: 0; padding: 1rem">
                    <p>
                        <span class="page-heading-tab">
                            {{ errorMsg }}
                        </span>
                    </p>
                </div>
                <div class="button-div">
                    <b-button @click="showError = false" pill variant="outline-secondary"
                        class="page-heading-tab btn start-course-bt ed-st-btn btn-secondary rounded-pill"
                        style="border-color: #274ba7; background-color: #274ba7; color: #fff; margin-top: 0.5em; width: 8.333333333333334rem; height: 2.9166666666666665rem">
                        OK
                    </b-button>
                </div>
                <!-- </template> -->
            </b-modal>
        </div>
    </div>
</template>
<script>
import "../../../common/css/CommonStyle.css";
import "../../../common/css/font-categories.css";
import Breadcrumb from "../../../components/common/Breadcrumb.vue";
import Loader from "../../../components/common/Loader.vue";
import ApiCalls from "@/common/api/apiCalls";
import Api from "../../../common/constants/api";
import { mapGetters } from "vuex";
import Roles from "@/common/constants/roles";

export default {
    name: "Classification",
    data() {
        return {
            showLoader: true,
            allMachineNotReplicated: false,
            allMachineReplicating: false,
            allReplicationCompleted: false,
            plantList: [],
            pillarList: [],
            committedYear: [],
            workCenterList: [],
            machineStatusList: [],
            workcenterId: "",
            plantId: 1,
            wcSelected: 5,
            selectedMonth: 1,
            showConfirmation: false,
            successConfirm: false,
            confirmationHeadText: "",
            currentDate: new Date(),
            monthsList: [
                { monthId: 1, monthName: "Jan" },
                { monthId: 2, monthName: "Feb" },
                { monthId: 3, monthName: "Mar" },
                { monthId: 4, monthName: "Apr" },
                { monthId: 5, monthName: "May" },
                { monthId: 6, monthName: "Jun" },
                { monthId: 7, monthName: "Jul" },
                { monthId: 8, monthName: "Aug" },
                { monthId: 9, monthName: "Sep" },
                { monthId: 10, monthName: "Oct" },
                { monthId: 11, monthName: "Nov" },
                { monthId: 12, monthName: "Dec" }
            ],
            machineDetailHeader: [
                {
                    key: "machineName",
                    label: "MACHINES",
                    sortable: false
                },
                {
                    label: "Alias",
                    key: "machineAlias",
                    sortable: false
                },
                {
                    label: "NOT REPLICATING",
                    key: "notReplicating",
                    statusId: 1,
                    sortable: false
                },
                {
                    label: "REPLICATING",
                    key: "replicating",
                    statusId: 0,
                    sortable: false,
                    variant: "success"
                },
                {
                    label: "REPLICATION COMPLETED   ",
                    key: "replCompleted",
                    statusId: 0,
                    sortable: false,
                    variant: "success"
                }
            ],
            machineDetail: [],
            allColsHeader: [],
            // showPopup: false,
            statusCount: { total: 0, nonRepl: 0, repl: 0, replComp: 0 },
            showUpdatebtn: false,
            roles: Roles,
            wcId: 0,
            editingMode: true,
            showError: false,
            errorMsg: ""
        };
    },
    components: {
        Breadcrumb,
        Loader
    },
    beforeCreate() {
        this.$store.dispatch("selectedMenu", {
            main: 4,
            subMenu: 6
        });
        this.$store.dispatch("breadcrumb", {
            title: "Plant Optimization - Machine Classification",
            breadcrumb: [
                { name: "Home / ", primary: true, redirect: "/dashboard" },
                { name: "Machine Classification", primary: false }
            ]
        });
    },
    mounted() { },
    created() {
        if (!(this.$oidc.userProfile.roles.includes(this.roles.PLANTPILLARLEADER) || this.$oidc.userProfile.roles.includes(this.roles.PLANTPOLEADER))) {
            this.$router.push("/");
            return;
        }
        this.getPlantDetails();
    },
    computed: {
        ...mapGetters(["plantSelectedInAsmtHome", "loggedInUserData", "prevSelectedWorkCenter"])
    },
    methods: {
        changingF(evt) {
            console.log(evt);
        },
        getPlantDetails() {
            ApiCalls.httpCallDetails(Api.GETPLANTLIST, "get").then((data) => {
                this.plantList = data.data;
                let plantId = this.plantSelectedInAsmtHome ? this.plantSelectedInAsmtHome : this.plantList[0].plantId;
                let index = 0;
                if (this.plantSelectedInAsmtHome) index = this.plantList.findIndex((el) => el.plantId == this.plantSelectedInAsmtHome);
                else if (this.loggedInUserData.plantId) {
                    let plantIndex = this.plantList.findIndex((el) => el.plantId == this.loggedInUserData.plantId);
                    if (plantIndex > -1) {
                        index = plantIndex;
                        plantId = this.plantList[index].plantId;
                    }
                } else if (this.loggedInUserData.regionName) {
                    let plantIndex = this.plantList.findIndex((el) => el.regionName == this.loggedInUserData.regionName);
                    if (plantIndex > -1) {
                        index = plantIndex;
                        plantId = this.plantList[index].plantId;
                    }
                }
                this.plantId = this.plantList[index];
                this.$store.dispatch("plantSelectedInAsmtHome", plantId);
                this.getWorkCenterDetails();
            });
        },
        plantChanged(event) {
            this.showLoader = true;
            console.log("plantChanged");
            this.$store.dispatch("plantSelectedInAsmtHome", event.target.value);
            this.plantId = this.plantList.find((el) => el.plantId == event.target.value);
            this.$store.dispatch("prevSelectedWorkCenter", 0);
            this.getWorkCenterDetails();
        },
        getWorkCenterDetails() {
            ApiCalls.httpCallDetails(Api.MRWORKCENTERALL, "post", { regionId: this.plantId.regionId }).then((data) => {
                this.workCenterList = data.data;
                let index = 0;
                if (this.prevSelectedWorkCenter) index = this.workCenterList.findIndex((el) => el.workcenterId == this.prevSelectedWorkCenter);
                this.wcId = this.workCenterList[index].workcenterId;
                this.$store.dispatch("prevSelectedWorkCenter", this.wcId);
                this.showLoader = false;
                this.filterHandler();
            });
        },
        workcenterChanged(event) {
            this.showLoader = true;
            this.$store.dispatch("prevSelectedWorkCenter", event.target.value);
            let changedWcId = this.workCenterList.find((el) => el.workcenterId == event.target.value);
            this.wcId = changedWcId.workcenterId;
            this.filterHandler();
        },
        filterHandler() {
            this.allMachineNotReplicated = false;
            this.allMachineReplicating = false;
            this.allReplicationCompleted = false;
            this.showLoader = true;
            let payloadMachineStatus = {
                plantId: this.plantId.plantId,
                wcId: this.wcId,
                date: new Date()
            };
            ApiCalls.httpCallDetails(Api.GETMACHINESTATUSLIST, "post", payloadMachineStatus).then((data) => {
                this.machineStatusList = data.data;
                if (this.machineStatusList.length !== 0) {
                    this.machineStatusList = data.data.map((machine) => {
                        if (machine.statusId == 0 || machine.statusId == null || machine.statusId == 1) {
                            this.allMachineNotReplicated = true;
                            return {
                                machineNotReplicated: true,
                                machineReplicating: false,
                                ReplicationCompleted: false,
                                machineAlias: machine.machineAlias,
                                modelValue: "non",
                                machineId: machine.machineId,
                                machineName: machine.machineName,
                                machineStatus: machine.machineStatus,
                                machineStatusId: machine.machineStatusId,
                                statusId: machine.statusId
                            };
                        }
                        if (machine.statusId == 2) {
                            this.allMachineReplicating = true;
                            return {
                                machineNotReplicated: false,
                                machineReplicating: true,
                                ReplicationCompleted: false,
                                machineAlias: machine.machineAlias,
                                modelValue: "repl",
                                machineId: machine.machineId,
                                machineName: machine.machineName,
                                machineStatus: machine.machineStatus,
                                machineStatusId: machine.machineStatusId,
                                statusId: machine.statusId
                            };
                        }
                        if (machine.statusId == 3) {
                            this.allReplicationCompleted = true;
                            return {
                                machineNotReplicated: false,
                                machineReplicating: false,
                                ReplicationCompleted: true,
                                machineAlias: machine.machineAlias,
                                modelValue: "comp",
                                machineId: machine.machineId,
                                machineName: machine.machineName,
                                machineStatus: machine.machineStatus,
                                machineStatusId: machine.machineStatusId,
                                statusId: machine.statusId
                            };
                        }
                    });
                    this.checkMarkedMachines();

                    setTimeout(() => {
                        this.machineStatusList.forEach((machine) => {
                            document.getElementById(`${machine.machineId}-id-${machine.modelValue}`).checked = true;
                        });
                    }, 100);
                } else {
                    this.statusCount = { total: 0, nonRepl: 0, repl: 0, replComp: 0 };
                }
                this.showLoader = false;
            });
        },
        saveHandle() {
            this.showConfirmation = false;
            let saveMCPayload = [];
            this.errorMsg = "";
            this.showError = false;
            this.headText = "";
            let flagObj = { foundDuplicate: false, machineIds: [] };
            this.showLoader = true;
            saveMCPayload = this.machineStatusList.map((item) => {
                let onlyAlias = this.machineStatusList.filter((j) => item.machineAlias && j.machineAlias === item.machineAlias);
                if (onlyAlias.length > 1) flagObj = { foundDuplicate: true, machineIds: [...flagObj.machineIds, item.machineName] };
                return {
                    machineId: item.machineId,
                    statusId: item.statusId ? item.statusId : 1,
                    machineAlias: item.machineAlias,
                    lastUpdatedBy: this.$oidc.userProfile.email
                };
            });
            console.log(flagObj);
            if (flagObj.foundDuplicate) {
                this.errorMsg = "Found duplicate alias name at :" + flagObj.machineIds.map((machine) => " " + machine);
                this.showError = true;
                this.headText = "";
                this.showLoader = false;
            } else
                ApiCalls.httpCallDetails(Api.SAVEMACHINECLASSIFICATION, "post", saveMCPayload).then((data) => {
                    this.successConfirm = true;
                    this.showLoader = false;
                });
        },
        onHeaderMachineCheck(event, key) {
            if (event && key === "allMachineNotReplicated") {
                this.allMachineNotReplicated = true;
                this.machineStatusList.forEach((machine) => {
                    machine.machineNotReplicated = true;
                    machine.machineReplicating = false;
                    machine.ReplicationCompleted = false;
                    machine.statusId = 1;
                    document.getElementById(`${machine.machineId}-id-non`).checked = true;
                });
                this.allMachineReplicating = false;
                this.allReplicationCompleted = false;
            } else if (!event && key === "allMachineNotReplicated") {
                this.machineStatusList.forEach((machine) => {
                    machine.machineNotReplicated = false;
                    document.getElementById(`${machine.machineId}-id-non`).checked = false;
                });
                this.allMachineReplicating = false;
                this.allReplicationCompleted = false;
            }

            if (event && key === "allMachineReplicating") {
                this.allMachineReplicating = true;
                this.machineStatusList.forEach((machine) => {
                    machine.machineReplicating = true;
                    machine.machineNotReplicated = false;
                    machine.ReplicationCompleted = false;
                    machine.statusId = 2;
                    document.getElementById(`${machine.machineId}-id-repl`).checked = true;
                });
                this.allMachineNotReplicated = false;
                this.allReplicationCompleted = false;
            } else if (!event && key === "allMachineReplicating") {
                this.machineStatusList.forEach((machine) => {
                    machine.machineReplicating = false;
                    document.getElementById(`${machine.machineId}-id-repl`).checked = false;
                });
                this.allMachineNotReplicated = false;
                this.allReplicationCompleted = false;
            }

            if (event && key === "allReplicationCompleted") {
                this.allReplicationCompleted = true;
                this.machineStatusList.forEach((machine) => {
                    machine.ReplicationCompleted = true;
                    machine.machineReplicating = false;
                    machine.machineNotReplicated = false;
                    machine.statusId = 3;
                    document.getElementById(`${machine.machineId}-id-comp`).checked = true;
                });
                this.allMachineReplicating = false;
                this.allMachineNotReplicated = false;
            } else if (!event && key === "allReplicationCompleted") {
                this.machineStatusList.forEach((machine) => {
                    machine.ReplicationCompleted = false;
                    document.getElementById(`${machine.machineId}-id-comp`).checked = false;
                });
                this.allMachineReplicating = false;
                this.allMachineNotReplicated = false;
            }
            this.checkMarkedMachines();
        },
        onMachineCheck(event, index, rowData, key) {
            if (key == "machineNotReplicated") {
                rowData.machineNotReplicated = true;
                rowData.machineReplicating = false;
                rowData.ReplicationCompleted = false;
                rowData.statusId = 1;
            }
            if (key == "machineReplicating") {
                rowData.machineReplicating = true;
                rowData.machineNotReplicated = false;
                rowData.ReplicationCompleted = false;
                rowData.statusId = 2;
            }
            if (key == "ReplicationCompleted") {
                rowData.ReplicationCompleted = true;
                rowData.machineReplicating = false;
                rowData.machineNotReplicated = false;
                rowData.statusId = 3;
            }

            this.checkMarkedMachines();
        },
        checkMarkedMachines() {
            let flagNonRPAllTrue = true;
            let flagRPAllTrue = true;
            let flagRPCAllTrue = true;
            this.statusCount = { total: this.machineStatusList.length, nonRepl: 0, repl: 0, replComp: 0 };
            this.machineStatusList.forEach((machine) => {
                if (!machine.machineNotReplicated) {
                    flagNonRPAllTrue = false;
                } else this.statusCount.nonRepl++;
                if (machine.machineReplicating === false) {
                    flagRPAllTrue = false;
                } else this.statusCount.repl++;
                if (machine.ReplicationCompleted === false) {
                    flagRPCAllTrue = false;
                } else this.statusCount.replComp++;
            });
            this.allMachineNotReplicated = flagNonRPAllTrue;
            this.allMachineReplicating = flagRPAllTrue;
            this.allReplicationCompleted = flagRPCAllTrue;
            this.showLoader = false;
        },
        showConfirmBox() {
            this.$refs.saveConfirmBox.show();
            this.confirmationHeadText = "";
            this.showConfirmation = true;
        },
        closeAlertPopup() {
            this.$refs["saveConfirmBox"].hide();
            this.showConfirmation = false;
        },
        closesuccessbox() {
            this.$refs["successConfirmBox"].hide();
            this.successConfirm = false;
        },
        okClick() {
            this.$router.push("/dashboard");
        }
    }
};
</script>

<style scoped>
/* radio */

.radio-class {
    justify-content: center;

    margin-bottom: 1rem;
}

/* radio */

.graph-div {
    width: 25%;
    padding: 0 1%;
}

.main-con {
    background: #fff;
    margin-top: 1em;
    border-radius: 6px;
}

.mat-info-con {
    padding: 2rem !important;
    display: flex;
}

.already-initiate-block {
    height: 50rem;
    display: flex;
    align-items: center;
    justify-content: center;
}

.plant-dd {
    width: 20.5%;
}

.asmt-bt:disabled {
    cursor: not-allowed;
    opacity: 0.3;
}

.asmt-bt:hover {
    border-color: #869ed8 !important;
    color: #274ba7 !important;
    background: #cad0e4 0% 0% no-repeat padding-box !important;
}

.asmt-bt {
    border-color: #869ed8 !important;
    background: #eff3ff 0% 0% no-repeat padding-box !important;
    color: #274ba7 !important;
}

.mat-needInput-con {
    margin-left: 2rem;
    display: flex;
    align-items: center;
}

.add-top-border {
    border-top: 1px solid #eae6e6;
}

.machine-classification-tracker {
    margin-top: 2rem;
    width: 100%;
}

.edit-button-div {
    display: flex;
}

.input-div {
    display: flex;
    align-items: center;
    justify-content: center;
    align-content: center;
    /* width: 50px; */
}

.input-f {
    width: 100%;
    font-size: 1.33rem;
    border-radius: 4px;
    padding: 0.1rem 0.5rem;
    border: #313131 1px solid;
}

#machine-classification-tracker th.table-success {
    background-color: #f4f6fd;
}

#machine-classification-tracker th:first-child {
    background-color: white !important;
    font-weight: medium;
}

#machine-target-setting tr td {
    border: 0px !important;
    border-bottom: 1px solid #eae6e6 !important;
}

.machine-target-setting-label {
    text-align: center !important;
    vertical-align: middle !important;
    font-size: 1.333rem;
    font-family: "BarlowM", sans-serif;
}

.machine-action-table-approve {
    max-height: 8rem !important;
}

.bold-font {
    font-family: "BarlowB", sans-serif;
}

.machine-action-table {
    max-height: 40rem !important;
}

.custom-control {
    display: flex !important;
}

.machine-action-table thead th {
    background-color: #f7f9ff !important;
}

.machine-action-table thead th,
.machine-action-table td {
    text-align: center !important;
    vertical-align: middle !important;
}

.save-submit-btn {
    background-color: #274ba7;
    border-color: #274ba7 !important;
    margin-top: 0.4rem;
}

.save-submit-btn:hover {
    background-color: #258cf3 !important;
    border-color: #258cf3 !important;
}

.save-submit-btn:active {
    background-color: #258cf3 !important;
    border-color: #258cf3 !important;
}

.save-submit-btn:focus {
    box-shadow: 0 0 0 0 rgb(130 138 145 / 50%) !important;
}

#machine-classification-tracker>.machine-action-table th,
td {
    border: 0px !important;
    border-right: 1px solid #eae6e6 !important;
    border-left: 1px solid #eae6e6 !important;
}

.machine-action-table-machine {
    text-align: left !important;
    font-size: 1.166rem;
    font-family: "BarlowR", sans-serif;
}

.machine-action-table th:first-child,
.machine-action-table td:first-child {
    /* width: 35%; */
    background-color: transparent !important;
    text-align: left !important;
}

.text-center {
    text-align: center !important;
}

.machine-action-table td,
.machine-action-table th div {
    color: #313131 !important;
    font-weight: 100;
}

.machine-action-table td tbody tr td {
    color: #313131 !important;
    vertical-align: middle;
}

.machine-action-table tbody tr:nth-child(even) {
    background-color: #fafafa !important;
}

.machine-action-table .table-light,
.machine-action-table .table-light>td,
.machine-action-table .table-light>th {
    background-color: #f0f0f0 !important;
}

.machine-action-table body tr td {
    color: #313131 !important;
    vertical-align: middle;
}

.machine-action-table th.table-success {
    background-color: #f4f6fd;
}

.machine-action-table td.table-success {
    background-color: transparent;
}

.button-div {
    display: flex;
    justify-content: flex-end;
}

.machine-action-table tr {
    height: 3rem;
}

.machine-action-table thead tr {
    border-bottom: 2px solid #dddddd !important;
}

.ontrack {
    width: 2rem;
    background: #3a5824;
    margin: 0 25%;
    height: 2rem;
    border-radius: 10rem;
    color: #fff !important;
}

.offtrack {
    width: 2rem;
    background: #c11a3b;
    margin: 0 25%;
    height: 2rem;
    border-radius: 10rem;
    color: #fff !important;
}

.row-month {
    display: flex;
    height: inherit;
    justify-content: center;
    align-items: center;
}

.even-space-vertical-align {
    justify-content: space-between;
    align-items: center;
    display: flex;
}

.btn {
    margin: 0 0 0 1rem;
    padding-left: 1.3rem;
    padding-right: 1.3rem;
}

.approval-work-centers {
    display: flex;
    min-height: inherit;
    max-height: 15rem;
    flex-wrap: wrap;
    flex-direction: row;
    align-content: flex-start;
}

.approval-heading {
    color: #234372;
    font-size: 1.3333333333333333rem;
    font-family: "BarlowSb", sans-serif;
    padding: 2rem;
    padding-bottom: 1rem;
    padding-top: 1rem;
}

/* .approval-heading {
    color: #234372;
    font-size: 1.3333333333333333rem;
    font-family: "BarlowSb", sans-serif;
    padding: 2rem;
    padding-bottom: 1rem;
    padding-top: 1rem;
} */

.approval-border-top {
    border-top: 1px solid #f0f0f0;
}

.approval-border-right {
    border-right: 1px solid #f0f0f0;
}

.individual-checkbox {
    /* min-width: 30%;
    max-width: 30%; */
    width: 20rem;
}

.review-background-active {
    background-color: #f0f7ff;
    border-radius: 6px;
    padding-top: 2em;
    padding-bottom: 2rem;
    max-width: 45%;
    margin-right: 5%;
}

.persona-heading {
    color: #313131;
    font-size: 1.1666666666666667rem;
    font-family: "BarlowR", sans-serif;
    padding: 2rem;
    padding-bottom: 1rem;
    padding-top: 1rem;
}

.approvals-save-button {
    padding: 1rem;
    float: right;
    text-align: end;
}

.approval-section-heading-disabled {
    color: #313131;
    opacity: 0.2;
    font-size: 1.1666666666666667rem;
    font-family: "BarlowR", sans-serif;
}

/* .approval-section-heading-disabled {
    color: #313131;
    opacity: 0.2;
    font-size: 1.1666666666666667rem;
    font-family: "BarlowR", sans-serif;
} */

.approval-section-heading {
    color: #313131;
    font-size: 1.1666666666666667rem;
    font-family: "BarlowR", sans-serif;
}

.display-flex-align-center {
    margin-left: 5px;
    display: flex;
    justify-content: center;
    text-align: center;
}

.approvals-updated-by {
    color: #5a5a5a;
    font-size: 0.9166666666666666rem;
    font-family: "BarlowR", sans-serif;
    text-align: center;
    padding: 1rem;
}

.table-container {
    height: 500px;
    width: 800px;
    overflow: auto;
}

.table-container table {
    height: 100%;
    width: 100%;
}

.chk-container {
    color: #313131;
    font-size: 1.1666666666666667rem;
    font-family: "BarlowR", sans-serif;
    padding: 1rem 0;
}

.pop-up-title {
    color: #234372;
    font-size: 1.3333333333333333rem;
    font-family: "BarlowSb", sans-serif;
}

.uploaded-items {
    list-style: none;
    padding: 0;
}

.uploaded-items-li {
    margin: 5px 0;
    display: flex;
    align-items: center;
}

.close-modal {
    background-color: transparent;
    border-color: transparent;
    color: #a3a3a3;
    font-size: 1.5rem;
    line-height: 0;
    padding: 0;
}

.edit-btn {
    height: 2.2rem;
    padding-top: 0.25rem !important;
    margin-left: 0.5rem;
}

.edit-btn:focus {
    background-color: #274ba7;
}

@media only screen and (max-width: 767px) {
    .mat-info-con {
        display: block !important;
    }

    .plant-dd {
        width: 100% !important;
    }
}
</style>
